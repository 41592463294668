<template>
  <div class="background">
    <section class="login-box">
      <v-fade-transition>
        <v-progress-linear
            style="position: absolute"
            v-if="processing" color="primary" indeterminate height="10px"/>
      </v-fade-transition>
      <div class="logo">
        <app-logo/>
        <h1>Login {{ typeLabel }}</h1>
      </div>
      <v-divider class="my-4"/>
      <div class="box-form text--secondary">
        <v-form ref="form" v-model="valid">
          <v-text-field
              v-if="fields[type].masks.login"
              :disabled="processing"
              filled
              append-icon="mdi-account"
              :rules="fields[type].rules.login"
              v-mask="fields[type].masks.login"
              :label="fields[type].labels.login" v-model="login"/>
          <v-text-field
              v-else
              :disabled="processing"
              filled
              append-icon="mdi-account"
              :rules="fields[type].rules.login"
              :label="fields[type].labels.login" v-model="login"/>
          <v-text-field
              v-if="fields[type].masks.password"
              :disabled="processing"
              filled
              append-icon="mdi-calendar-range"
              :label="fields[type].labels.password"
              v-mask="fields[type].masks.password"
              v-model="password"
              :type="fields[type].types.password"/>
          <v-text-field
              v-else
              :disabled="processing"
              filled
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :label="fields[type].labels.password"
              v-model="password"
              @click:append="showPassword = !showPassword"
              :type="showPassword ? 'text' : fields[type].types.password"/>
        </v-form>

        <v-btn color="primary"
               @click="onEnterClick" :disabled="processing || !valid" block large>Entrar</v-btn>
        <v-fade-transition>
          <p class="error--text d-flex align-center ma-0 mt-4 font-weight-bold" v-if="errorMessage">
            <v-icon color="error" class="mb-1">mdi-alert</v-icon> {{errorMessage}}
          </p>
        </v-fade-transition>
      </div>
      <div class="box-form-outros text--primary" v-if="eCliente">
        <v-divider class="my-4"/>
        É nosso Cliente e ainda não tem Cadastro?
        <v-btn color="warning"
               @click="rotaCadastrese" block large>Cadastre-se</v-btn>
      </div>
      <div class="box-form-outros text--primary" v-if="eCliente">
        <v-divider class="my-4"/>
        Quer somente se inscrever em algum Evento? 
        <v-row>
          <v-col cols="12" sm="12">
            <v-btn color="primary" @click="rotaInscricaoOE" block large>Outros Eventos</v-btn>
          </v-col>
        </v-row>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLogo from "../../components/app/AppLogo";
import {LOGIN_MODES} from "../../store/modules/auth";
import {ApiError} from "../../api/core";

export default {
  components: {AppLogo},
  props: ['type'],
  name: "Login",
  data() {
    return {
      valid: false,
      errorMessage: null,
      login: '',
      password: '',
      showPassword: false,
      processing: false,
      fields: {
        [LOGIN_MODES.CLIENT]: {
          types: {
            password: 'text'
          },
          masks: {
            login: "###.###.###-##",
            password: '##/##/####'
          },
          rules: {
            login: [
              this.$validators.string.required,
              this.$validators.string.cpf
            ],
            password: [
              this.$validators.string.required,
              this.$validators.string.notEmpty,
            ]
          },
          labels: {
            login: 'CPF',
            password: 'Data de Nascimento'
          },
          postProcessor: {
            login(v) {
              return v;
            }
          }
        },
        [LOGIN_MODES.EMPLOYEE]: {
          types: {
            password: 'password'
          },
          masks: {
            login: null,
            password: null
          },
          rules: {
            login: [
              this.$validators.string.required,
              this.$validators.string.notEmpty,
            ],
            password: [
              this.$validators.string.required,
              this.$validators.string.notEmpty,
            ]
          },
          labels: {
            login: 'Login',
            password: 'Senha'
          },
          postProcessor: {
            login(v) {
              return v;
            }
          }
        }
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'token', 'isAuthenticated']),
    typeLabel() {
      return this.type === LOGIN_MODES.CLIENT ? 'Cliente' : 'Funcionário'
    },
    eCliente() {
      return this.type === LOGIN_MODES.CLIENT ? true : false
    }
  },
  methods: {
    ...mapActions('auth', ['doLogin']),
    async onEnterClick() {
      if(!this.$refs.form.validate()) return;
      try {
        this.errorMessage = null;
        this.processing = true;

        await new Promise((resolve) => {
          setTimeout(() => resolve(), 1000);
        })
        
        await this.doLogin({
          type: this.type,
          login: this.fields[this.type].postProcessor.login(this.login),
          password: this.password
        });

      } catch (err) {
        if (err instanceof ApiError) {
          if (err.message==="duplicado"){
            this.$toast.error(`Mais de um Cliente encontrado com as credencias fornecidas. Entre em contato com a administração da Federação Baiana, Capixaba e Mineira de Golfe!`, {
              timeout: 6000
            })
          } else {
            this.$toast.error(err.message, {
              timeout: 6000
            })
          }
        } else {
          this.$toast.error(`Não foi possível efetuar o login. Entre em contato com a administração da Federação Baiana, Capixaba e Mineira de Golfe!`, {
            timeout: 6000
          })
        }
      } finally {
        this.processing = false;
      }
    },
    rotaCadastrese (){
      this.$router.push({
                    name: 'Cadastre-se'
                  });
    },
    rotaInscricaoCF (){
      this.$router.push({
                    name: 'InscricaoCopaFuturo'
                  });
    },
    rotaInscricaoCA (){
      this.$router.push({
                    name: 'InscricaoCircuitoAmizade'
                  });
    },
    rotaInscricaoOE (){
      this.$router.push({
                    name: 'NossosEventos'
                  });
    },
    mostrarOcultaSenha(){
      return 
    }
  },
  mounted(){
  }
}
</script>

<style scoped lang="scss">

.background {
  background-image: url('../../assets/backgrounds/bg01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  flex: 1;

  .login-box {
    width: 100%;
    max-width: 450px;
    height: auto;
    position: fixed;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    left: 50%;
    top: 0;
    bottom: 0;
    background-color: rgba(245, 245, 245, 0.73);
    border-right: 1px solid #dedede;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dedede;
    overflow-y: auto; 
    transform: translateX(-50%); /* Centraliza horizontalmente */
    .logo {
      padding-top: 15px;
      padding-left: 15px;
      img {
        height: 60px;
      }
    }
  }

  .box-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
  }
  .box-form-outros {
    flex-direction: column;
    justify-content: center;
    padding: 0 15px 15px 15px;
    border-top: 1px solid #dedede;
  }

/* Aplica margem apenas em dispositivos com largura máxima de 767 pixels (típico de dispositivos móveis) */
@media only screen and (max-width: 767px) {
    .login-box {
        margin: 2px;
    }
}

}
</style>